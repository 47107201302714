<template>
  <div class="oposList">
    <div class="loadingDiv" v-if="loading">Lade Daten...</div>
    <div v-else>
      <div class="row">
        <div class="col-12">
          <div class="btn-group mb-3 w-100">
            <div class="col-3">
              <button @click="getSEPAXml()" class="btn btn-primary btn-sm me-2"><i class="fa-duotone fa-file-export"></i>
                Export SEPA XML
                <template v-if="Object.keys(selected).length > 0">({{ Object.keys(selected).length }})</template>
                <template v-else>(Alle)</template>
              </button>
<!--              <button @click="postMahnungen()" class="btn btn-outline-primary btn-sm"><i class="fa-duotone fa-exclamation-triangle"></i>-->
<!--                Mahnung-->
<!--                <template v-if="Object.keys(selected).length > 0">({{ Object.keys(selected).length }})</template>-->
<!--                <template v-else>(Alle)</template>-->
<!--              </button>-->
            </div>
            <div class="col-4">
              <div class="btn-group me-2">
              <button @click="show('all')" :class="(showType === 'all') ? 'btn-primary' : 'btn-outline-primary'"
                      class="btn btn-outline-primary btn-sm"><i class="fa-duotone fa-filter-list"></i> Alle
              </button>
              <button @click="show('sepa')" :class="(showType === 'sepa') ? 'btn-primary' : 'btn-outline-primary'"
                      class="btn btn-outline-primary btn-sm"><i class="fa-duotone fa-filter-circle-dollar"></i>
                 SEPA
              </button>

              <button @click="show('debit')" :class="(showType === 'debit') ? 'btn-primary' : 'btn-outline-primary'"
                      class="btn btn-outline-primary btn-sm"><i class="fa-duotone fa-filter-list"></i> Debit
              </button>
              <button @click="show('credit')" :class="(showType === 'credit') ? 'btn-primary' : 'btn-outline-primary'"
                      class="btn btn-outline-primary btn-sm"><i class="fa-duotone fa-filter-list"></i> Credit
              </button>
              </div>
              <button @click="showType2 = !showType2" :class="(showType2) ? 'btn-primary' : 'btn-outline-primary'"
                      class="btn btn-outline-primary btn-sm"><i class="fa-duotone fa-hourglass-clock"></i> Überfällig</button>
            </div>
            <div class="col-5 text-end">
              <button @click="exportOpos('list')" class="btn me-3 btn-primary btn-sm"><i
                class="fa-duotone fa-file-lines"></i> Export OPOS-Liste
              </button>
              <button @click="exportOpos('single')" class="btn btn-primary btn-sm me-3"><i
                class="fa-duotone fa-memo-circle-info"></i> Export OPOS-Buchungen
              </button>
              <button @click="getAccountList()" class="btn btn-primary btn-sm "><i class="fa-duotone fa-file-user"></i>
                Export Konten
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="table-responsive p-0" style="max-height: 600px;">
        <table class="accountingDataTable table table-striped">
          <thead>
          <tr>
            <th>&nbsp;</th>
            <th class="sepa">SEPA</th>
            <th>Konto</th>
            <th>Titel</th>
            <th>Typ</th>
            <th class="right">Saldo ({{$filters.priceEuro(oposSaldo)}})</th>
            <th class="rightInner">
              <table class="belege">
                <tr>
                  <td class="sum">Betrag</td>
                  <td class="nr">Beleg</td>
                  <td class="belegdate">Fälligkeit</td>
                  <td class="mahnstufe">Stufe</td>
                  <td class="aktion">Aktion</td>
                </tr>
              </table>
            </th>
          </tr>
          </thead>
          <tbody>
          <template v-for="(opos, key) in oposList">
            <template v-if="!showType2 || (showType2 && opos.overdue)">
            <tr :key="opos.id" v-if="(showType === 'credit' && accounts[key].type === 'kreditor') || (showType === 'debit' && accounts[key].type === 'debitor') || showType === 'all' || (showType === 'sepa' && opos.sepa)" :class="(opos.sum >= 0) ? 'greenRow' : 'redRow'">
              <td><input type="checkbox" @change="toggleSelected(accounts[key].id)"
                         :checked="selected[accounts[key].id]"></td>

              <td class="sepa">
                <template v-if="!opos.sepa" ><span tooltip="kein Sepa aktiv" position="right"><i class="fa-duotone fa-xmark-circle"></i></span></template>
                <template v-else><span tooltip="Sepa aktiv" position="right"><i class="fa-duotone fa-wallet"></i></span></template>

              </td>
              <td>{{ key }}</td>
              <td>{{ accounts[key].title }}</td>
              <td>{{accounts[key].type}}</td>
              <td class="right">{{ $filters.priceEuro(opos.sum) }}</td>
              <td class="rightInner">
                <table class="belege">
                  <tr :key="nr" v-for="(beleg, nr) in opos.items">
                    <td class="sum">{{ $filters.priceEuro(beleg.sum) }}</td>
                    <td class="nr">{{ nr }}</td>
                    <td class="belegdate" v-if="beleg && beleg.info   && beleg.info.pay_due">
                      <template v-if="beleg.info.daysLeft <= 0">
                        <span class="red">{{$filters.formatDate(beleg.info.pay_due)}}</span>
                      </template>
                      <template v-else>
                      {{$filters.formatDate(beleg.info.pay_due)}}
                      </template>
                    </td>
                    <td class="mahnstufe" v-if="beleg && beleg.info && beleg.info.lastReminder && beleg.info.mahnstufe"><span :tooltip="beleg.info.lastReminder" position="left"  >{{beleg.info.mahnstufe}}</span></td>
                    <td class="aktion">
                      <div class="btn-group" v-for="item in beleg.items" v-if="beleg.items && beleg.items.length > 0 && beleg.items.length === 1">

                        <template v-if="!item.exported">
                          <button @click="editBookingEmit(item)" style="margin-bottom: 0; vertical-align: middle"
                                  title="Editieren" class="btn  btn-xs  btn-success"><i
                            class="fa-duotone fa-pencil"></i></button>
                          <button @click="removeBookingEmit(item.id)" style="margin-bottom: 0; vertical-align: middle"
                                  title="Löschen" class="btn btn-xs   btn-primary"><i class="fa-duotone fa-close"></i>
                          </button>
                        </template>
                      </div>
                    </td>
                  </tr>

                </table>
              </td>
            </tr></template>
          </template>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";

export default {
  name: 'OposList',
  data() {
    return {
      loading: true,
      oposList: [],
      accounts: [],
      selected: {},
      oposSaldo: 0,
      showType: 'all',
      showType2: false
    }
  },

  methods: {
    toggleSelected(id) {
      if (!this.selected[id]) {
        this.selected[id] = id;
      } else {
        delete this.selected[id];
      }
    },
    show(type) {
      this.showType = type;
    },
    loadOposList() {
      this.loading = true;
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.state.apiUrl + "accounting/oposList", {headers}).then((r) => {
        this.oposList = r.data.result.data.data;
        // calc sum
        this.calcSaldo();
        this.accounts = r.data.result.data.accounts;
        this.loading = false;
      });
    },
    calcSaldo() {
      let sum = 0;
      for (let key in this.oposList) {

        if ((this.showType === 'credit' && this.accounts[key].type === 'kreditor') || (this.showType === 'debit' && this.accounts[key].type === 'debitor') || this.showType === 'all' || (this.showType === 'sepa' && this.oposList[key].sepa)) {
          if(this.showType2 && !this.oposList[key].overdue){
            continue;
          }
          sum += parseFloat(this.oposList[key].sum);
        }
      }
      this.oposSaldo = sum;
    },
    postMahnungen(){
      this.loading = true;
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      //convert selected to url string
      let selected = '';
      for (let key in this.selected) {
        selected += '&selected[]=' + this.selected[key];
      }
      axios.get(this.$store.state.apiUrl + "accounting/oposList?mahnung=1" + selected, {headers}).then((r) => {
        this.$store.dispatch("toastSuccess", "Mahnungen erfolgreich versendet");
        this.loading = false;
      }).catch((e) => {
        this.$store.dispatch("handleApiResponse", e.response.data);
      });
    },
    getSEPAXml() {
      this.loading = true;
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      //convert selected to url string
      let selected = '';
      for (let key in this.selected) {
        selected += '&selected[]=' + this.selected[key];
      }
      axios.get(this.$store.state.apiUrl + "accounting/oposList?xml=1" + selected, {headers}).then((r) => {
        if (r.data.result.data) {
          // force download zip file from base64
          let fileData = r.data.result.data;
          const linkSource = 'data:'+fileData.mime+';base64,' + fileData.data;

          let fileName = fileData.name;
          // download fileName
          // trigger download
          let a = window.document.createElement('a');
          a.setAttribute('href', linkSource);
          a.setAttribute('download', fileName);
          window.document.body.appendChild(a);
          a.click();
        } else {
          this.$store.dispatch("toastError", "Keine SEPA-Datei vorhanden");
        }
        this.loading = false;
      }).catch((e) => {
        this.$store.dispatch("handleApiResponse", e.response.data);
      });
    },
    getAccountList() {
      this.loading = true;
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.state.apiUrl + "accounting/accountExport", {headers}).then((r) => {
        this.oposList = r.data.result.data;
        // force download zip file from base64
        const linkSource = 'data:text/csv;base64,' + r.data.result.data;
        let now = new Date();
        let fileName = 'export_sepa_' + now.getFullYear() + '-' + (now.getMonth() + 1) + '-' + now.getDate() + '.csv';
        // download fileName
        // trigger download
        let a = window.document.createElement('a');
        a.setAttribute('href', linkSource);
        a.setAttribute('download', fileName);
        window.document.body.appendChild(a);
        a.click();
        this.loading = false;
      });
    },

    editBookingEmit(booking) {
      this.$emit("editBooking", booking);
    },
    removeBookingEmit(id) {
      this.$emit("removeBooking", id);
    },
    exportOpos(overView) {
      this.loading = true;
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.state.apiUrl + "accounting/oposList?csv=1&overview=" + overView+"&overdue="+this.showType2, {headers}).then((r) => {
        // force download zip file from base64
        const linkSource = 'data:text/csv;base64,' + r.data.result.data;
        let now = new Date();
        let fileName = 'export_opostlist_' + now.getFullYear() + '-' + (now.getMonth() + 1) + '-' + now.getDate() + '.csv';
        // download fileName
        // trigger download
        let a = window.document.createElement('a');
        a.setAttribute('href', linkSource);
        a.setAttribute('download', fileName);
        window.document.body.appendChild(a);
        a.click();
        this.loading = false;
      });
    }
  },
  created() {
    this.loadOposList();
  },
  watch: {
    showType() {
      this.calcSaldo();
    },
    showType2(){
      this.calcSaldo();
    }
  }
}
</script>

<style>
tr.redRow td.sum {
  color: #b21f28;
  font-weight: bold;
}
</style>
